import { useState } from "react";
import Headler from "./Headler";
import Footer from "./Footer";

function App() {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [message, setMessage] = useState("");
  const [feedbackSent, setFeedbackSent] = useState(false);

  const faqs = [
    {
      question: "What information does the app provide?",
      answer:
        "All ingredents information including what it is, benefits and risks, in details.",
    },
    {
      question: "How to use it?",
      answer: "Just need product front and ingredent label pictures.",
    },
    {
      question: "can I cancel it or delete my account?",
      answer:
        "You can cancel or delete your account anytime you want in the app.",
    },
    // {
    //   question: "I have more questions?",
    //   answer: "Please feel free to reach us at care@cmytip.com.",
    // },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const subscribe = async () => {
    if (!email) {
      alert("Please enter email");
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(email)) {
      alert("Please enter valid email");
      return;
    }

    try {
      const res = await fetch("https://cmytip.com/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (res.status === 200) {
        setSubscribed(true);
        setEmail("");
        // alert("Subscribed successfully");
      } else {
        alert("Failed to subscribe");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DISCORD_URL =
    "https://discord.com/api/webhooks/1287503561869234358/8jzRO9p-onwWkT83ifkxphQv7-39g0jXJnssjIbq8lbCwVchKbIOzRu4kK_tauAFZVs9";

  const sendfeedback = async () => {
    if (!message) {
      alert("Please enter message");
      return;
    }
    try {
      const response = await fetch(DISCORD_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: message }),
      });

      if (response.ok) {
        setFeedbackSent(true);
        setMessage("");
      } else {
        alert("Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="flex flex-col items-center w-full  select-none">
      <Headler />
      <div
        id="problem"
        className="laptop:h-[660px] w-full laptop:p-12 p-8 gap-8 flex flex-col laptop:flex-row justify-center items-center"
      >
        <div className="laptop:w-1/2">
          <div className="flex flex-row items-center py-2 gap-2">
            <img className="h-6 w-6" src="/assets/problem.png" alt="logo" />
            <div className="mt-2">What is the problem?</div>
          </div>
          <div className="laptop:text-6xl text-3xl font-bold">
            We help you avoid harmful ingredients
          </div>
          <div className="laptop:text-[18px] font-light mt-8 ">
            Modern industrialization introduced so much toxic chemicals in our
            food, drinks, makeups, household items, such as preservatives, food
            dye/colors, artificial flavors, additives and more. They cause
            deseases like diabetes, cardiovascular problems, cancers etc. We
            need to know and understand these risks ourselves. Here comes
            CMyTip.
          </div>
        </div>
        <div className="laptop:w-1/2 flex flex-row justify-center items-center gap-2 ">
          {/* <img
            className="laptop:h-[420px] h-[260px]"
            src="/assets/homebg.png"
            alt="logo"
          />
          <img
            className="laptop:h-[420px] h-[260px]"
            src="/assets/homebg1.png"
            alt="logo"
          /> */}
          <div className="">
            <iframe
              width="100%"
              height="260"
              src="https://www.youtube.com/embed/37aUGxtF9Uk"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div className="text-xs">
              Red Dye No. 3 had been banned in cosmetics as well as in EU 35
              years ago, is made from petroleum, but US consumers have been
              swallowing it all these decades. It links to cancer and
              neurological damages.
            </div>
          </div>
        </div>
      </div>

      <div className="h-[460px] w-full bg-blue-50 hidden laptop:flex">
        <section
          id="container1"
          className="bg-[url('./assets/market.png')] bg-fixed bg-center bg-cover w-full h-full flex flex-col justify-center items-center rounded"
        >
          <h1 className="text-white text-center text-6xl font-bold">
            What is the solution?
          </h1>
        </section>
      </div>
      {/* //a horizontal line with a text in the middle */}
      <div className="w-full flex flex-row justify-center items-center mt-6">
        <div className="w-1/2 flex flex-row justify-center items-center gap-2">
          <div className="w-1/2 h-0.5 bg-gray-300"></div>
          <div className="text-2xl font-bold text-gray-500 whitespace-nowrap">
            Our Solution
          </div>
          <div className="w-1/2 h-0.5 bg-gray-300"></div>
        </div>
      </div>
      <div
        id="solution"
        className="laptop:h-[660px] w-full laptop:p-12 p-8 gap-8 flex flex-col laptop:flex-row justify-center items-center"
      >
        <div className="laptop:w-1/2">
          <div className="flex flex-row items-center py-2 gap-2">
            <img className="h-6 w-6" src="/assets/solution1.png" alt="logo" />
            <div className="mt-2">Expand our intelligence!</div>
          </div>
          <div className="laptop:text-6xl text-3xl font-bold">
            We help you understand the good and the bad
          </div>
          <div className="laptop:text-[18px] font-light mt-8 ">
            What they are, why they are added, what benefits and risks, what
            needs your attention. Take a picture and that's all, the app will
            help you to make informed decisions. It can be food, beverage,
            cosmetics, household products and all categories. It is time to
            equip yourself and protect yourself.
          </div>
        </div>
        <div className="w-1/2 flex flex-row justify-center items-center gap-2 ">
          <img
            className="laptop:h-[420px] h-[260px]"
            src="/assets/label.png"
            alt="logo"
          />
          <img
            className="laptop:h-[420px] h-[260px]"
            src="/assets/label1.png"
            alt="logo"
          />
        </div>
      </div>
      <div
        id="how"
        className="laptop:h-[660px] w-full laptop:p-12 p-8 gap-8 flex flex-col laptop:flex-row justify-center items-center bg-slate-100"
      >
        <div className="laptop:w-1/2">
          <div className="flex flex-row items-center py-2 gap-2">
            <img className="h-6 w-6" src="/assets/app.png" alt="logo" />
            <img className="h-6 w-6" src="/assets/app1.png" alt="logo" />
            <div className="mt-2">Try free. No credit card.</div>
          </div>
          <div className="laptop:text-6xl text-3xl font-bold">
            We help you live healthier.
          </div>

          <div className="laptop:text-[18px] font-light mt-8 ">
            Download for iOS or Android, try it for free. No credit card. Just
            take a picture.
          </div>
          <div className="flex flex-row laptop:gap-8 gap-4 ">
            <div className="flex flex-row">
              <a
                href="https://apps.apple.com/us/app/cmytip/id6739454796"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="h-20"
                  src="/assets/AppleStore1.png"
                  alt="logo"
                />
              </a>
            </div>
            <div className="flex flex-row">
              <a
                href="https://play.google.com/store/apps/details?id=com.cmytip"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="h-20"
                  src="/assets/GooglePlay1.png"
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <div className="flex flex-row gap-4 mt-6">
            <input
              type="email"
              placeholder="Enter your email"
              className="p-2 px-4 laptop:w-96 rounded border"
              value={email}
              onChange={(e) => setEmail(e.target.value || "")}
            />
            <button
              onClick={subscribe}
              className="py-2 px-4 bg-red-500 text-white rounded"
            >
              Subscribe
            </button>
          </div>
          <div className="h-6 mt-2 px-2">
            {subscribed && (
              <div className="text-green-500 text-xs">
                Subscribed successfully
              </div>
            )}
          </div>
          <div className="text-sm">
            100K+ people are already using it. Join them. Or test it for free,
            no credit card.
          </div>
        </div>
        <div className="w-1/2 flex flex-row justify-center items-center gap-2">
          <img
            className="laptop:h-[420px] h-[260px]"
            src="/assets/product1.png"
            alt="logo"
          />
          <video
            className="laptop:h-[420px] h-[260px] px-4 bg-slate-300"
            autoPlay
            loop
            muted
            // poster="/assets/product4.png"
          >
            <source src="/assets/webdemo1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div
        id="cost"
        className="laptop:h-[660px] w-full laptop:p-12 p-8 gap-8 flex flex-col laptop:flex-row justify-center items-center"
      >
        <div className="laptop:w-1/2">
          <div className="flex flex-row items-center py-2 gap-2">
            <img className="h-7 w-7" src="/assets/cost.png" alt="logo" />
            <div className="mt-2">What is the price?</div>
          </div>
          <div className="laptop:text-6xl text-3xl font-bold">
            The price of not knowing what you consume?
          </div>
          <div className="laptop:text-[18px] font-light mt-8 ">
            The medical industry is there with open arms. CMyTip is to help you
            stay away from these man made diseases and live healthier. Only
            $9.99/month, cancel anytime in the app. No questions asked. Protect
            your health and your family's. It works for most of languages.
          </div>
        </div>
        <div className="w-1/2 flex flex-row justify-center items-center gap-2">
          <img
            className="laptop:h-[420px] h-[260px]"
            src="/assets/doctor1.png"
            alt="logo"
          />
          <img
            className="laptop:h-[420px] h-[260px]"
            src="/assets/cost1.png"
            alt="logo"
          />
        </div>
      </div>
      <div
        id="faq"
        className="laptop:h-[660px] w-full laptop:p-12 p-8 gap-8 flex flex-col laptop:flex-row justify-center items-center bg-slate-100"
      >
        <div className="laptop:w-1/2">
          <div className="flex flex-row items-center py-2 gap-2">
            <img className="h-8 w-8" src="/assets/people.png" alt="logo" />
            <div className="mt-2">Questions?</div>
          </div>
          <div className="laptop:text-6xl text-3xl font-bold">
            Is it worth the price?
          </div>
          <div className="laptop:text-[18px] font-light mt-8 ">
            Try it for free. No credit card. Take a picture and see the results
            yourself.
          </div>
          <div className="max-w-3xl mx-auto p-4">
            <h1 className="laptop:text-2xl text-xl font-bold text-center mb-6">
              Frequently Asked Questions
            </h1>
            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <div key={index} className="border rounded shadow-md">
                  <button
                    className="w-full p-4 text-left laptop:text-lg font-medium bg-gray-100 hover:bg-gray-200"
                    onClick={() => toggleFAQ(index)}
                  >
                    {faq.question}
                  </button>
                  <div
                    className={`overflow-hidden transition-all duration-300 ${
                      activeIndex === index ? "max-h-40 p-4" : "max-h-0 p-0"
                    } bg-white`}
                  >
                    <p className="text-gray-700">{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-1/2 flex flex-row justify-center items-center gap-2 ">
          {/* <img
            className="laptop:h-[420px] h-[260px]"
            src="/assets/product4.png"
            alt="logo"
          /> */}
          <img
            className="laptop:h-[420px] h-[260px]"
            src="/assets/product2.png"
            alt="logo"
          />

          <img
            className="laptop:h-[420px] h-[260px]"
            src="/assets/product3.png"
            alt="logo"
          />
        </div>
      </div>
      <div
        id="feedback"
        className="laptop:h-[660px] w-full laptop:p-12 p-8 laptop:gap-8 flex flex-col laptop:flex-row justify-center items-center bg-white"
      >
        <div className="laptop:w-1/2">
          <div className="flex flex-row items-center py-2 gap-2">
            <img className="h-6 w-6" src="/assets/review.png" alt="logo" />
            <div className="mt-2">We love to hear from you.</div>
          </div>
          <div className="laptop:text-6xl text-3xl font-bold">
            Please help us improve
          </div>

          <div className="laptop:text-[18px] font-light mt-8 ">
            We receive feedbacks and suggestions from users everyday. We are
            working hard to improve the app. Please feel free to send us yours.
          </div>

          <div className="flex flex-col gap-4 mt-6">
            <textarea
              placeholder="Enter your message"
              className="p-2 px-4 rounded border"
              value={message}
              rows={4}
              onChange={(e) => setMessage(e.target.value || "")}
            ></textarea>

            <button
              onClick={sendfeedback}
              className="py-2 px-8 bg-red-500 text-white rounded"
            >
              Send
            </button>
          </div>
          <div className="h-6 mt-2 px-2">
            {feedbackSent && (
              <div className="text-green-500 text-xs">
                Message sent successfully
              </div>
            )}
          </div>
        </div>
        <div className="laptop:w-1/2 flex flex-row justify-center items-center">
          <img
            className="laptop:h-[440px] h-[260px] w-auto"
            src="/assets/demobanner2.png"
            alt="logo"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
