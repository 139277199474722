import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className=" bg-slate-50 pt-12 pb-20 flex flex-col justify-center items-center w-screen h-full text-sm  text-slate-500 z-10 border-t-2 gap-2">
      <div className="flex flex-row gap-5 ">
        <a
          href="https://apps.apple.com/us/app/cmytip/id6739454796"
          target="_blank"
          rel="noreferrer"
        >
          <img className="h-[69px]" src="/assets/AppleStore1.png" alt="logo" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.cmytip"
          target="_blank"
          rel="noreferrer"
        >
          <img className="h-[69px]" src="/assets/GooglePlay1.png" alt="logo" />
        </a>
      </div>

      <div className="flex flex-col mt-6">
        <div className="flex items-center gap-3">
          <div className="h-8 w-8">
            <img
              className="object-cover opacity-70"
              src="/assets/clogo2.png"
              alt="logo"
            />
          </div>
          <div className=" md:text-xl font-black uppercase">CMYTIP </div>
          <div className="ml-4">Built with care in Seattle</div>
        </div>
        <div className="mt-3 text-xs font-extralight">
          @2023 - {currentYear} CMyTip | All Rights Reserved | Minute AI
        </div>
      </div>

      <div className="flex flex-row gap-8 mt-4">
        <a className="hover:underline" href="/">
          Home
        </a>
        <a className="hover:underline" href="/privacy">
          Privacy Policy
        </a>
        <a className="hover:underline" href="/terms">
          Terms of Service
        </a>
        {/* <a className="hover:underline" href="/contact">
          Feedback
        </a> */}
      </div>
    </footer>
  );
};

export default Footer;
