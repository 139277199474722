import { useNavigate } from "react-router-dom";
// import Headler from "./Headler";
import Footer from "./Footer";

function Terms() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center w-full  select-none">
      <div className="flex flex-row px-20 py-4 items-center justify-between top-0 w-screen z-20  backdrop-blur-sm bg-white/80 border-b-2">
        <div className="flex flex-row items-center">
          <img
            className="object-cover h-10 mr-3"
            src="/assets/clogo1.png"
            //   src="/assets/clogo4.png"
            alt="logo"
          />
          <div
            onClick={() => navigate("/")}
            className=" text-2xl font-semibold hover:cursor-pointer"
          >
            SeeMyTip
          </div>
        </div>
        {/* <div className="flex flex-row items-center justify-between gap-6 text-sm pt-3">
          <a href="#problem" className="p-2">
            PROBLEM
          </a>
          <a href="#solution" className="p-2">
            SOLUTION
          </a>
          <a href="#how" className="p-2">
            HOW
          </a>
          <a href="#cost" className="p-2">
            COST
          </a>
        </div> */}
        {/* <div
          onClick={() => navigate("/")}
          className="flex flex-row items-center p-2 gap-2"
        >
          <div className="text-sm mr-2">GET THE APP</div>
          <img className="h-6 w-6" src="/assets/app.png" alt="logo" />
          <img className="h-6 w-6" src="/assets/app1.png" alt="logo" />
        </div> */}
      </div>
      <div className="max-w-4xl mx-auto p-6 bg-white text-gray-800">
        <h1 className="text-3xl font-bold text-center mb-8 ">
          Terms of Service
        </h1>

        <div className="space-y-6">
          <div className="border rounded-lg p-4 bg-gray-50">
            <h2 className="text-xl font-semibold ">1. Acceptance of Terms</h2>
            <div className="mt-4 text-gray-700 space-y-3">
              <p>
                By accessing or using the cmytip application, you agree to be
                bound by these Terms of Service, which constitute a legal
                agreement between you and cmytip.
              </p>

              <h3 className="font-semibold text-lg mt-4">
                Key Terms of Agreement
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>You must be at least 13 years old to use cmytip</li>
                <li>You agree to provide accurate and current information</li>
                <li>
                  You accept responsibility for all activities under your
                  account
                </li>
                <li>You commit to using the service legally and ethically</li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">
                Modifications to Terms
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>
                  cmytip reserves the right to modify these terms at any time
                </li>
                <li>Changes will be communicated through app notifications</li>
                <li>
                  Continued use of the app constitutes acceptance of new terms
                </li>
                <li>Material changes will be announced with a 30-day notice</li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">User Eligibility</h3>
              <ul className="list-disc list-inside pl-4">
                <li>Minimum age requirement: 13 years</li>
                <li>Users under 18 require parental/guardian consent</li>
                <li>
                  Residents of all jurisdictions where service is legally
                  permitted
                </li>
                <li>
                  No use by those previously banned or removed from the service
                </li>
              </ul>

              <p className="italic text-sm text-gray-600 mt-4">
                * By using cmytip, you acknowledge that you have read,
                understood, and agree to these terms * These terms form a
                binding legal agreement
              </p>
            </div>
          </div>

          <div className="border rounded-lg p-4 bg-gray-50">
            <h2 className="text-xl font-semibold ">
              2. User Account and Responsibilities
            </h2>
            <div className="mt-4 text-gray-700 space-y-3">
              <p>
                Creating and maintaining an account with cmytip requires
                adherence to specific guidelines and responsibilities.
              </p>

              <h3 className="font-semibold text-lg mt-4">Account Creation</h3>
              <ul className="list-disc list-inside pl-4">
                <li>Provide accurate and complete registration information</li>
                <li>Choose a secure password</li>
                <li>Maintain account confidentiality</li>
                <li>Immediately report unauthorized account access</li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">
                User Conduct and Restrictions
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>Prohibited from using the app for illegal activities</li>
                <li>No harassment, discrimination, or harmful content</li>
                <li>No attempts to circumvent app security</li>
                <li>
                  No reproduction or distribution of app content without
                  permission
                </li>
                <li>
                  No use of automated systems or scripts to interact with the
                  app
                </li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">
                Content Ownership and Usage
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>Users retain ownership of uploaded content</li>
                <li>Grant cmytip limited license to process uploaded images</li>
                <li>Responsible for content compliance with terms</li>
                <li>cmytip may remove inappropriate or violating content</li>
                <li>
                  The data provided is not medical advice, it is for education
                  only.
                </li>
              </ul>

              <p className="text-sm text-red-600 mt-4">
                Violation of these terms may result in account suspension or
                termination.
              </p>
            </div>
          </div>

          <div className="border rounded-lg p-4 bg-gray-50">
            <h2 className="text-xl font-semibold ">
              3. Service Usage and Limitations
            </h2>
            <div className="mt-4 text-gray-700 space-y-3">
              <p>
                cmytip provides an AI-powered product label understanding
                service with specific usage terms and service limitations.
              </p>

              <h3 className="font-semibold text-lg mt-4">Service Scope</h3>
              <ul className="list-disc list-inside pl-4">
                <li>AI-powered product label analysis</li>
                <li>Temporary image processing</li>
                <li>Informational and educational purposes only</li>
                <li>
                  Not a substitute for professional medical or legal advice
                </li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">
                Performance and Availability
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>No guaranteed 100% uptime or availability</li>
                <li>Periodic maintenance and updates may interrupt service</li>
                <li>AI accuracy is not absolute and may have limitations</li>
                <li>Results are based on current AI model capabilities</li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">
                Intellectual Property
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>
                  All app design, software, and AI technologies are cmytip
                  property
                </li>
                <li>Protected by copyright and intellectual property laws</li>
                <li>No reverse engineering or unauthorized reproduction</li>
                <li>Trademarks and logos are cmytip's exclusive property</li>
              </ul>

              <p className="text-sm italic text-gray-600 mt-4">
                * Service features and availability may change without prior
                notice * Users advised to verify critical information
                independently
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8 text-center text-gray-600">
          <p>Last Updated: December 8, 2024</p>
          <p className="text-sm">© 2024 cmytip. All rights reserved.</p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Terms;
